import React from 'react'
import './documentation.css';


const DocumentationPage = () => {
    return ( 
        <div className="DocumentationPage container">
            <div className="row">
                <div className="col-md-12">
                    <p id="documentation_page_header">API Documentation</p>

                    <p className="text_paragraph" id="text_section_one">
                        This API documentation page provides information on how to use the weather forecast API. The API is a RESTful API that provides access that allows you to get weather forecast data for any location around the world.The API is easy to use and can be accessed for free. The documentation provides detailed instructions on how to use it. The endpoints, parameters, and data formats.
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <p className="sub_header">Try to use</p>
                    <p className="code_snippet overflow-auto" onClick={() => navigator.clipboard.writeText('https://marktestserver.pythonanywhere.com/getopenmeteodata/?latitude=14.6760&longitude=121.0437')}style={{ cursor: 'pointer' }}>
                        https://marktestserver.pythonanywhere.com/getopenmeteodata/?latitude=14.6760&longitude=121.0437
                        </p>
                    <p className="text_paragraph" id="text_section_two">
                        When searched through a browser, The API will return a django REST-Framework HTML output. The API will return the corret format (a JSON file if request was comming from the terminal of through code) 
                    </p>
                    
                    <p className="sub_header">In python Code</p>
                    <p>
                        The api can be use directly with python using the request library. In this example we will be using python version 3.x and windows os
                        <br />
                        First is to install the request library<br />
                    </p>
                    <p className="code_snippet overflow-auto">python -m pip install requests</p>
                    <p>if error: try</p>
                    <p className="code_snippet overflow-auto">python pip install requests</p>
                    <p>We can now use request in python. Create a new python file and add the following code</p>
                    <div id="code_block">
                        <p className="code_snippet overflow-auto">
                            import requests<br></br>
                            url = https://marktestserver.pythonanywhere.com/getopenmeteodata/?latitude=14.6760&longitude=121.0437<br></br>
                            reponse = requests.get(url)<br></br>
                            json_parsed = response.json()<br></br>
                            print(json_parsed)<br></br>
                        </p>
                    </div>

                    <p className="sub_header">API Parameters</p>
                    <div className='table_container overflow-auto'>
                        <table className="my_table">
                            <thead>
                                <tr className="row_type_dark">
                                    <th>Argument</th>
                                    <th>DataType</th>
                                    <th>Required</th>
                                    <th>Default</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>latitude</td>
                                    <td>Float</td>
                                    <td>Yes</td>
                                    <td>NoValue</td>
                                    <td>Geographical coordinate of the target location.</td>
                                </tr>
                                <tr>
                                    <td>longitude</td>
                                    <td>Float</td>
                                    <td>Yes</td>
                                    <td>NoValue</td>
                                    <td>Geographical coordinate of the target location.</td>
                                </tr>
                                <tr>
                                    <td>hparams</td>
                                    <td>String</td>
                                    <td>No</td>
                                    <td>None</td>
                                    <td>Hourly weather parameters. String value seperated by comma. (e.g. temperature_2m,relativehumidity_2m,windspeed_10m,precipitation,rain). See Hourly parameters list</td>
                                </tr>

                                <tr>
                                    <td>dparams</td>
                                    <td>String</td>
                                    <td>No</td>
                                    <td>None</td>
                                    <td>Daily weather parameters. String value seperated by comma. (e.g. temperature_2m_max,temperature_2m_min,winddirection_10m_dominant). See Daily parameters list</td>
                                </tr>

                                <tr>
                                    <td>apitoken</td>
                                    <td>String</td>
                                    <td>Yes/No</td>
                                    <td>NoValue</td>
                                    <td>API token is a unique string that is used to request weather forecast with more options. If API token was not provided only default parameters are available</td>
                                </tr>
                                <tr>
                                    <td>currentweather</td>
                                    <td>Boolean</td>
                                    <td>No</td>
                                    <td>True</td>
                                    <td>Include current weather conditions in the output.</td>
                                </tr>
                                <tr>
                                    <td>temperatureunit</td>
                                    <td>String</td>
                                    <td>No</td>
                                    <td>celcius</td>
                                    <td>Unit used in the output</td>
                                </tr>
                                <tr>
                                    <td>windspeedunit</td>
                                    <td>String</td>
                                    <td>No</td>
                                    <td>kmp</td>
                                    <td>Unit used in the output</td>
                                </tr>
                                <tr>
                                    <td>precipitationunit</td>
                                    <td>String</td>
                                    <td>No</td>
                                    <td>mm</td>
                                    <td>Unit used in the output</td>
                                </tr>
                                <tr>
                                    <td>timeformat</td>
                                    <td>String</td>
                                    <td>No</td>
                                    <td>iso8601</td>
                                    <td>format used in displaying time</td>
                                </tr>
                                <tr>
                                    <td>timezone</td>
                                    <td>String</td>
                                    <td>No</td>
                                    <td>utc</td>
                                    <td>Unit used in the getting time</td>
                                </tr>
                                <tr>
                                    <td>pastdays</td>
                                    <td>Integer</td>
                                    <td>No</td>
                                    <td>0</td>
                                    <td>Include yesterday or the day before yesterday data in the JSON output</td>
                                </tr>
                                <tr>
                                    <td>forecastdays</td>
                                    <td>Integer</td>
                                    <td>No</td>
                                    <td>6</td>
                                    <td>How many days freom the current date will be included in the data, Up to 16 days forecast</td>
                                </tr>

                                <tr>
                                    <td>startend</td>
                                    <td>Boolean</td>
                                    <td>No</td>
                                    <td>False</td>
                                    <td>Time interval to get weather data. A day must be specified as an ISO8601 date (e.g. 2022-06-30).</td>
                                </tr>

                                <tr>
                                    <td>startdate</td>
                                    <td>String</td>
                                    <td>No</td>
                                    <td>None</td>
                                    <td>Time interval to get weather data. Must be specified as an ISO8601 date (e.g. 2022-06-30).</td>
                                </tr>

                                <tr>
                                    <td>enddate</td>
                                    <td>String</td>
                                    <td>No</td>
                                    <td>None</td>
                                    <td>Time interval to get weather data. Must be specified as an ISO8601 date (e.g. 2022-06-30).</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <p className="sub_header">Hourly Variables</p>
                    <div className="table_container overflow-auto">
                        <table className="my_table">
                            <thead>
                                <tr>
                                    <th>Variable</th>
                                    <th>Unit</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>temperature_2m</td>
                                    <td>°C</td>
                                    <td>Air temperature at 2 meters above ground level. This is the temperature that we typically feel.</td>
                                </tr>
                                <tr>
                                    <td>relativehumidity_2m</td>
                                    <td>%</td>
                                    <td>Percentage of water vapor in the air at 2 meters above ground level.</td>
                                </tr>
                                <tr>
                                    <td>dewpoint_2m</td>
                                    <td>°C</td>
                                    <td>Temperature at which the air would become saturated with water vapor if cooled at constant pressure.</td>
                                </tr>
                                <tr>
                                    <td>apparent_temperature</td>
                                    <td>°C</td>
                                    <td>Temperature that feels like due to the combined effects of air temperature, wind speed, and humidity.</td>
                                </tr>
                                <tr>
                                    <td>pressure_msl</td>
                                    <td>hPa</td>
                                    <td>Air pressure at mean sea level. This is the pressure that would be felt if we were standing on the ocean surface.</td>
                                </tr>
                                <tr>
                                    <td>surface_pressure</td>
                                    <td>hPa</td>
                                    <td>Air pressure at the surface. This is the pressure that we typically feel.</td>
                                </tr>
                                <tr>
                                    <td>cloudcover</td>
                                    <td>%</td>
                                    <td>Percentage of the sky that is covered by clouds.</td>
                                </tr>
                                <tr>
                                    <td>cloudcover_low</td>
                                    <td>%</td>
                                    <td>Percentage of the sky that is covered by clouds at or below 2,000 meters above ground level.</td>
                                </tr>
                                <tr>
                                    <td>cloudcover_mid</td>
                                    <td>%</td>
                                    <td>Percentage of the sky that is covered by clouds between 2,000 and 6,000 meters above ground level.</td>
                                </tr>
                                <tr>
                                    <td>cloudcover_high</td>
                                    <td>%</td>
                                    <td>Percentage of the sky that is covered by clouds above 6,000 meters above ground level.</td>
                                </tr>



                                <tr>
                                    <td>windspeed_10m</td>
                                    <td>m/s</td>
                                    <td>Wind speed at 10 meters above ground level. This is the wind speed that is typically measured at weather stations.</td>
                                </tr>
                                <tr>
                                    <td>windspeed_80m</td>
                                    <td>m/s</td>
                                    <td>Wind speed at 80 meters above ground level. This is the wind speed that is important for aviation.</td>
                                </tr>
                                <tr>
                                    <td>windspeed_120m</td>
                                    <td>m/s</td>
                                    <td>Wind speed at 120 meters above ground level. This is the wind speed that is important for wind energy production.</td>
                                </tr>
                                <tr>
                                    <td>windspeed_180m</td>
                                    <td>m/s</td>
                                    <td>Wind speed at 180 meters above ground level. This is the wind speed that is important for atmospheric research.</td>
                                </tr>
                                <tr>
                                    <td>winddirection_10m</td>
                                    <td>°</td>
                                    <td>Wind direction at 10 meters above ground level. This is the direction from which the wind is blowing.</td>
                                </tr>
                                <tr>
                                    <td>winddirection_80m</td>
                                    <td>°</td>
                                    <td>Wind direction at 80 meters above ground level. This is the direction from which the wind is blowing.</td>
                                </tr>
                                <tr>
                                    <td>winddirection_120m</td>
                                    <td>°</td>
                                    <td>Wind direction at 120 meters above ground level. This is the direction from which the wind is blowing.</td>
                                </tr>
                                <tr>
                                    <td>winddirection_180m</td>
                                    <td>°</td>
                                    <td>Wind direction at 180 meters above ground level. This is the direction from which the wind is blowing.</td>
                                </tr>
                                <tr>
                                    <td>windgusts_10m</td>
                                    <td>m/s</td>
                                    <td>Maximum wind gust at 10 meters above ground level. This is the strongest wind speed that has been recorded in a short period of time.</td>
                                </tr>
                                <tr>
                                    <td>shortwave_radiation</td>
                                    <td>W/m²</td>
                                    <td>Shortwave radiation received from the sun. This is the energy that drives the weather and climate.</td>
                                </tr>
                                <tr>
                                    <td>direct_radiation</td>
                                    <td>W/m²</td>
                                    <td>Shortwave radiation that is not scattered by the atmosphere. This is the strongest component of shortwave radiation.</td>
                                </tr>
                                <tr>
                                    <td>diffuse_radiation</td>
                                    <td>W/m²</td>
                                    <td>Shortwave radiation that is scattered by the atmosphere. This is the weaker component of shortwave radiation.</td>
                                </tr>
                                <tr>
                                    <td>vapor_pressure_deficit</td>
                                    <td>hPa</td>
                                    <td>Difference between the actual vapor pressure and the saturation vapor pressure. This is a measure of how much moisture the air can hold.</td>
                                </tr>
                                <tr>
                                    <td>cape</td>
                                    <td>J/kg</td>
                                    <td>Convective Available Potential Energy. This is a measure of the potential for thunderstorms to develop.</td>
                                </tr>
                                <tr>
                                    <td>evapotranspiration</td>
                                    <td>mm/day</td>
                                    <td>Amount of water vapor that is transferred from the land to the atmosphere by evaporation and transpiration.</td>
                                </tr>
                                <tr>
                                    <td>et0_fao_evapotranspiration</td>
                                    <td>mm/day</td>
                                    <td>Reference evapotranspiration. This is the amount of water vapor that would be evaporated from a reference surface under standard conditions.</td>
                                </tr>
                                <tr>
                                    <td>precipitation</td>
                                    <td>mm</td>
                                    <td>Amount of water that falls to the ground as rain, snow, sleet, or hail.</td>
                                </tr>
                                <tr>
                                    <td>weathercode</td>
                                    <td>-</td>
                                    <td>International code that describes the type of weather.</td>
                                </tr>
                                <tr>
                                    <td>snow_depth</td>
                                    <td>mm</td>
                                    <td>Amount of snow that has accumulated on the ground.</td>
                                </tr>
                                <tr>
                                    <td>showers</td>
                                    <td>-</td>
                                    <td>Whether or not there are showers in the forecast.</td>
                                </tr>
                                <tr>
                                    <td>snowfall</td>
                                    <td>mm</td>
                                    <td>Amount of snow that is expected to fall in the forecast period.</td>
                                </tr>
                                <tr>
                                    <td>visibility</td>
                                    <td>km</td>
                                    <td>Distance that can be seen in the prevailing atmospheric conditions.</td>
                                </tr>
                                <tr>
                                    <td>precipitation_probability</td>
                                    <td>%</td>
                                    <td>Probability of precipitation occurring in the forecast period.</td>
                                </tr>
                                <tr>
                                    <td>freezinglevel_height</td>
                                    <td>m</td>
                                    <td>Height of the freezing level. This is the altitude at which the air temperature is 0°C.</td>
                                </tr>
                                <tr>
                                    <td>soil_temperature_0cm</td>
                                    <td>°C</td>
                                    <td>Temperature of the soil at a depth of 0 centimeters.</td>
                                </tr>
                                <tr>
                                    <td>soil_temperature_6cm</td>
                                    <td>°C</td>
                                    <td>Temperature of the soil at a depth of 6 centimeters.</td>
                                </tr>
                                <tr>
                                    <td>soil_temperature_18cm</td>
                                    <td>°C</td>
                                    <td>Temperature of the soil at a depth of 18 centimeters.</td>
                                </tr>
                                <tr>
                                    <td>soil_temperature_54cm</td>
                                    <td>°C</td>
                                    <td>Temperature of the soil at a depth of 54 centimeters.</td>
                                </tr>
                                <tr>
                                    <td>soil_moisture_0_1cm</td>
                                    <td>%</td>
                                    <td>Percentage of water in the soil at a depth of 0-1 centimeters.</td>
                                </tr>
                                <tr>
                                    <td>soil_moisture_1_3cm</td>
                                    <td>%</td>
                                    <td>Percentage of water in the soil at a depth of 1-3 centimeters.</td>
                                </tr>
                                <tr>
                                    <td>soil_moisture_3_9cm</td>
                                    <td>%</td>
                                    <td>Percentage of water in the soil at a depth of 3-9 centimeters.</td>
                                </tr>
                                <tr>
                                    <td>soil_moisture_9_27cm</td>
                                    <td>%</td>
                                    <td>Percentage of water in the soil at a depth of 9-27 centimeters.</td>
                                </tr>
                                <tr>
                                    <td>soil_moisture_27_81cm</td>
                                    <td>%</td>
                                    <td>Percentage of water in the soil at a depth of 27-81 centimeters.</td>
                                </tr>
                                <tr>
                                    <td>is_day</td>
                                    <td>-</td>
                                    <td>Whether or not it is day or night.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    <p className="sub_header">Daily Variables</p>
                    <div className="table_container overflow-auto">
                        <table className="my_table">
                            <thead>
                                <tr>
                                <th>Variables</th>
                                <th>Unit</th>
                                <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>temperature_2m_max</td>
                                <td>°C</td>
                                <td>Maximum 2-meter temperature</td>
                                </tr>
                                <tr>
                                <td>temperature_2m_min</td>
                                <td>°C</td>
                                <td>Minimum 2-meter temperature</td>
                                </tr>
                                <tr>
                                <td>apparent_temperature_max</td>
                                <td>°C</td>
                                <td>Maximum apparent temperature</td>
                                </tr>
                                <tr>
                                <td>apparent_temperature_min</td>
                                <td>°C</td>
                                <td>Minimum apparent temperature</td>
                                </tr>
                                <tr>
                                <td>precipitation_sum</td>
                                <td>mm</td>
                                <td>Total precipitation</td>
                                </tr>
                                <tr>
                                <td>precipitation_hours</td>
                                <td>hours</td>
                                <td>Number of hours with precipitation</td>
                                </tr>
                                <tr>
                                <td>weathercode</td>
                                <td>number</td>
                                <td>NWS weather code</td>
                                </tr>
                                <tr>
                                <td>sunrise</td>
                                <td>HH:mm</td>
                                <td>Sunrise time</td>
                                </tr>
                                <tr>
                                <td>sunset</td>
                                <td>HH:mm</td>
                                <td>Sunset time</td>
                                </tr>
                                <tr>
                                <td>windspeed_10m_max</td>
                                <td>m/s</td>
                                <td>Maximum 10-meter wind speed</td>
                                </tr>
                                <tr>
                                <td>windgusts_10m_max</td>
                                <td>m/s</td>
                                <td>Maximum 10-meter wind gust</td>
                                </tr>
                                <tr>
                                <td>winddirection_10m_dominant</td>
                                <td>°</td>
                                <td>Dominant 10-meter wind direction</td>
    </tr>
    <tr>
      <td>shortwave_radiation_sum</td>
      <td>W/m²</td>
      <td>Total shortwave radiation</td>
    </tr>
    <tr>
      <td>et0_fao_evapotranspiration</td>
      <td>mm</td>
      <td>FAO reference evapotranspiration</td>
    </tr>
    <tr>
      <td>uv_index_max</td>
      <td>number</td>
      <td>Maximum UV index</td>
    </tr>
    <tr>
      <td>uv_index_clear_sky_max</td>
      <td>number</td>
      <td>Maximum UV index under clear sky conditions</td>
    </tr>
  </tbody>
</table>

                    </div>


                </div>
            </div>
        </div>
     );
}
 
export default DocumentationPage;


/**


 */